'use client'

import * as React from 'react'
import { ThemeProvider as NextThemesProvider } from 'next-themes'
import type { ThemeProviderProps } from 'next-themes'

export function ThemeProvider({
  children,
  defaultTheme = 'light',
  forcedTheme = 'light',
  ...props
}: ThemeProviderProps) {
  const [mounted, setMounted] = React.useState(false)

  React.useEffect(() => {
    setMounted(true)
  }, [])

  // Always force light mode during SSR and initial client render
  if (!mounted) {
    return <div className="light">{children}</div>
  }

  return (
    <NextThemesProvider
      defaultTheme={defaultTheme}
      forcedTheme={forcedTheme}
      {...props}
    >
      {children}
    </NextThemesProvider>
  )
}
