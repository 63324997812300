'use client'

// This component provides UI elements for CookieBot which is loaded by GTM

export function CookieBotSettings() {
  return (
    // Official CookieBot button for managing consent
    <button
      id="CookieDeclarationChangeConsent"
      className="text-base text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100"
    >
      Cookie Settings
    </button>
  )
}

export function CookieBotDeclarationSection() {
  return (
    // Container for CookieBot declaration that will be populated by GTM
    <div className="mt-8 border-t border-gray-200 dark:border-gray-800 pt-8">
      {/* This div will be populated by the CookieBot loaded via GTM */}
      <div id="CookieDeclaration"></div>
    </div>
  )
}
