'use client'

import * as React from 'react'
import {
  BrainCog,
  ChartBarIcon,
  Menu,
  X,
  CreditCardIcon,
  ArrowUpRightIcon,
  Headset,
} from 'lucide-react'
import { useTheme } from 'next-themes'
import Link from 'next/link'

import { Button } from '@/components/ui/button'
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from '@/components/ui/navigation-menu'
import { SignedOut, SignedIn, SignInButton, UserButton } from '@clerk/nextjs'
import Image from 'next/image'

export default function Navbar() {
  const { setTheme, theme } = useTheme()
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen)

  const NavItems = () => (
    <>
      <SignedOut>
        <NavigationMenuItem>
          <Link href="/#pricing" legacyBehavior passHref>
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>
              Pricing
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <Link href="/#about" legacyBehavior passHref>
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>
              About
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>
      </SignedOut>
      <SignedIn>
        <NavigationMenuItem>
          <Link href="/dashboard/call" passHref>
            <Button variant="default">
              <Headset className="ml-2" /> Talk
            </Button>
          </Link>
        </NavigationMenuItem>
      </SignedIn>

      <NavigationMenuItem>
        <SignedOut>
          <Link href="/onboarding" legacyBehavior passHref>
            <Button variant="default">
              Get Started <ArrowUpRightIcon className="ml-2" />
            </Button>
          </Link>
        </SignedOut>
        <SignedIn>
          <Link href="/dashboard" legacyBehavior passHref>
            <Button variant="default">
              Dashboard <ArrowUpRightIcon className="ml-2" />
            </Button>
          </Link>
        </SignedIn>
      </NavigationMenuItem>
      {/*<NavigationMenuItem>
        <Button
          variant="outline"
          size="icon"
          onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
        >
          <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
          <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
          <span className="sr-only">Toggle theme</span>{' '}
        </Button>*
      </NavigationMenuItem>*/}
      <NavigationMenuItem>
        <SignedOut>
          <SignInButton fallbackRedirectUrl="/dashboard" />
        </SignedOut>
        <SignedIn>
          <div className="pl-2 pt-1">
            <UserButton>
              <UserButton.MenuItems>
                <UserButton.Link
                  label="Dashboard"
                  labelIcon={<ChartBarIcon />}
                  href="/dashboard"
                />
                <UserButton.Link
                  label="AI settings"
                  labelIcon={<BrainCog />}
                  href="/dashboard/settings/instructions"
                />
                <UserButton.Link
                  label="Manage subscription"
                  labelIcon={<CreditCardIcon />}
                  href="/dashboard/settings/subscription"
                />
              </UserButton.MenuItems>
            </UserButton>
          </div>
        </SignedIn>
      </NavigationMenuItem>
    </>
  )

  return (
    <header className="container mx-auto px-4 py-6 light bg-white">
      <div className="flex justify-between items-center">
        <Link href="/" aria-label="TidyCalls Logo">
          <Image
            height={26}
            width={100}
            src="https://res.cloudinary.com/dyf0ydr0a/image/upload/f_auto,q_auto,w_100/TidyCalls-Logo-2025_merksm.png"
            alt="TidyCalls Logo"
          />
        </Link>
        <div className="hidden md:block light">
          <NavigationMenu className="light">
            <NavigationMenuList className="light">
              <NavItems />
            </NavigationMenuList>
          </NavigationMenu>
        </div>
        <div className="md:hidden">
          <Button
            variant="ghost"
            size="icon"
            onClick={toggleMenu}
            className="light"
          >
            {isMenuOpen ? <X /> : <Menu />}
          </Button>
        </div>
      </div>
      {isMenuOpen && (
        <div className="md:hidden mt-4 light">
          <NavigationMenu className="flex flex-col space-y-2 light">
            <NavigationMenuList className="flex flex-col space-y-2 light">
              <NavItems />
            </NavigationMenuList>
          </NavigationMenu>
        </div>
      )}
    </header>
  )
}
